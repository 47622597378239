import styled from "styled-components";
import { DashboardLayout } from "src/components/dashboard";
import { BookingsTabs } from "src/components/bookings";
import { Input } from "src/components/inputs";
import searchIcon from "src/assets/images/input/searchIcon.svg";
import React, { useEffect, useState } from "react";
import { Button, ButtonClass, Flex } from "src/components/ui";
import { BookingStatus } from "src/components/bookings";
import { useNavigate, useSearchParams } from "react-router-dom";
import { formatDateDmy } from "src/utils/helpers";
import bookingAdminService from "src/service/BookingAdmin";
import { useLoading, useExportData } from "src/hooks";
import { Loader } from "src/components/common";
import { toast, ToastContainer } from "react-toastify";
import { theme } from "src/styles/Theme";

const BookingsPageContainer = styled.div``;

interface Props {
  handleChange: (e: any) => void;
  onClick: () => void;
}

const initialAddressState = {
  city: "",
  state: "",
  house_address: "",
};

const initialMetaState = {
  call_out_fee: 0,
  display_picture: "",
  first_name: "",
  last_name: "",
  phone: "",
  rating: "",
  address: initialAddressState,
};

const initialBookingState = {
  artisan_id: "",
  city: "",
  service: "",
  state: "",
  status: "",
  createdAt: "",
  user_id: "",
  description: "",
  artisan_meta: initialMetaState,
  user_meta: initialMetaState,
};
export default function BookingsPage() {
  const navigate = useNavigate();
  const [bookingDetails, setBookingDetails] = useState<BookingsTypes[]>([
    initialBookingState,
  ]);
  const [searchField, setSearchField] = useState("");

  const {
    loading: fetchingBookings,
    startLoading: startFetchingBookings,
    stopLoading: stopFetchingBookings,
  } = useLoading(false);

  useEffect(() => {
    document.title = "Booking's Page";
  }, []);

  useEffect(() => {
    startFetchingBookings();
    bookingAdminService
      .bookingHistory()
      .then((res) =>
        setBookingDetails(res?.data?.payload?.data || initialBookingState)
      )
      .catch((err) => {
        // console.log(err?.response?.data?.error?.message);
        toast.error(err?.response?.data?.error?.message);
      })
      .finally(() => stopFetchingBookings());
  }, []);

  const filteredData = bookingDetails.filter((data) => {
    return (
      data.user_meta.first_name
        .toLowerCase()
        .includes(searchField.toLowerCase()) ||
      data.user_meta.last_name
        .toLowerCase()
        .includes(searchField.toLowerCase()) ||
      data.service.toLowerCase().includes(searchField.toLowerCase()) ||
      data.city.toLowerCase().includes(searchField.toLowerCase()) ||
      data.state.toLowerCase().includes(searchField.toLowerCase()) ||
      data.artisan_meta.first_name
        .toLowerCase()
        .includes(searchField.toLowerCase()) ||
      data.artisan_meta.last_name
        .toLowerCase()
        .includes(searchField.toLowerCase())
    );
  });

  const handleChange = (e: any) => {
    setSearchField(e.target.value);
  };

  let [searchParams, setSearchParams] = useSearchParams();

  const handleNavigate = (row: { [x: string]: any }) => {
    navigate(`/bookings/${row?._id}?tabStatus=${row?.status}`);
  };

  const BookingsTableHeaders = [
    {
      title: "Customer",
      render: (row: BookingsTypes) => (
        <Flex gap="10px" align="center">
          <img
            style={{ width: 40, height: 40, borderRadius: "50%" }}
            src={row.user_meta.display_picture}
            alt=""
          />{" "}
          {row.user_meta.first_name} {row.user_meta.last_name}
        </Flex>
      ),
    },
    {
      title: "Vendor",
      render: (row: BookingsTypes) => (
        <Flex gap="10px" align="center">
          <img
            style={{ width: 40, height: 40, borderRadius: "50%" }}
            src={row.artisan_meta.display_picture}
            alt=""
          />{" "}
          {row.artisan_meta.first_name} {row.artisan_meta.last_name}
        </Flex>
      ),
    },
    { title: "Service", render: (row: BookingsTypes) => `${row.service}` },
    {
      title: "Location",
      render: (row: BookingsTypes) => `${row.city}, ${row.state}`,
    },
    {
      title: "Date",
      render: (row: BookingsTypes) => formatDateDmy(row.createdAt),
    },
    {
      title: "Status",
      render: (row: BookingsTypes) => <BookingStatus status={row.status} />,
    },
  ];

  //Exporting data to csv format
  const bookingHeaders = BookingsTableHeaders.map((item) => item.title);
  const DATA_TO_EXPORT = bookingDetails.map((row) => {
    return {
      customer: `${row.user_meta.first_name} ${row.user_meta.last_name}`,
      vendor: `${row.artisan_meta.first_name} ${row.artisan_meta.last_name}`,
      service: `${row.service}`,
      location: `${row.city}, ${row.state}`,
      date: formatDateDmy(row.createdAt),
      status: row.status,
    };
  });
  const getExportedData = useExportData(DATA_TO_EXPORT, bookingHeaders);

  return (
    <DashboardLayout
      pageTitle="Bookings"
      rhsHeading={
        <RhsHeading handleChange={handleChange} onClick={getExportedData} />
      }
    >
      <ToastContainer />
      <BookingsPageContainer>
        {fetchingBookings ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "100px",
            }}
          >
            <Loader>loading...</Loader>{" "}
          </div>
        ) : filteredData.length > 0 ? (
          <BookingsTabs
            rows={filteredData}
            BookingsTableHeaders={BookingsTableHeaders}
            title={
              <p className="count">
                {filteredData.length > 1
                  ? filteredData.length + " Bookings"
                  : filteredData.length + " Booking"}
              </p>
            }
            onRowClick={handleNavigate}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        ) : (
          <p className="table-entry-status">No Bookings Found</p>
        )}
      </BookingsPageContainer>
    </DashboardLayout>
  );
}

export const RhsHeading: React.FC<Props> = ({ handleChange, onClick }) => (
  <Flex wrap="wrap">
    <Button
      onClick={onClick}
      classes={[ButtonClass.SOLID, ButtonClass.WITH_ICON]}
      style={{
        backgroundColor: theme.colors.purple,
        height: "48px",
        minWidth: "145px",
      }}
    >
      <span>Export</span>
    </Button>
    <Input
      icon={<img src={searchIcon} alt="searchIcon" />}
      type="search"
      placeholder="Search"
      handleChange={handleChange}
    />
  </Flex>
);
