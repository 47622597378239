import adminServiceApi from 'src/utils/AdminServiceApi';

export const requestServices = {
    getAllUsersRequests: async () => {
        return adminServiceApi().get(`user/v1/admin/wallet/withdraw-request`);
    },
    validateUserRequest: async (
        transaction_id: string,
        action: string
    ) => {
        return adminServiceApi().put(`user/v1/admin/wallet/withdraw-request/:${transaction_id}?action=${action}`)
    },
    getAllArtisansRequests: async () => {
        return adminServiceApi().get(`artisan/v1/admin/wallet/withdraw-request`);
    },
    validateArtisansRequest: async (
        transaction_id: string,
        action: string
    ) => {
        return adminServiceApi().put(`artisan/v1/admin/wallet/withdraw-request/:${transaction_id}?action=${action}`)
    },
}