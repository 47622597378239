import { FC, useState, useEffect } from "react";
import Button from "src/components/ui/widget/Button";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { auth } from "src/store/Auth";
import KycApprovedModal from "src/components/kyc/kycModals/KycApprovedModal";
import RejectionModal from "src/components/kyc/kycModals/RejectionModal";
import { useLoading } from "src/hooks";
import VerificationService from "src/service/VerifyService";
import AppModal from "src/components/ui/widget/Modal/Modal";
import ModalContent from "src/components/common/ModalContent";

const FaceRecognition: FC<{
  id: string;
  who: string;
  fetchData: Function;
  verifyData: { [key: string]: any };
}> = ({ id, verifyData, who, fetchData }) => {
  const { formState, handleSubmit, register, control, setValue } = useForm({
    mode: "onChange",
  });
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    fetchData(id);
  };
  //Check if Logged Admin is an Occupational Manager
  const { authUser } = auth.use();

  const [isOccupManager, setIsOccupManager] = useState(false)

  useEffect(() => {
    //Set State to true is Occupational Manager is logged in
    if(authUser?.role?.includes('occupationalManager')){
      setIsOccupManager(true)
    }
  }, [authUser]);

  const [openReject, setOpenReject] = useState(false);
  const handleRejectOpen = () => setOpenReject(true);
  const handleRejectClose = () => {
    setOpenReject(false);
    fetchData(id);
  };
  const [rejectionReason, setRejectionReason] = useState("");
  const [showModal, setShowModal] = useState(false);

  const {
    loading: rejectingKyc,
    startLoading: startRejectingKyc,
    stopLoading: stopRejectingKyc,
  } = useLoading(false);

  const handleRejectKyc = handleSubmit(() => {
    if (rejectingKyc) {
      return;
    }
    startRejectingKyc();
    id &&
      VerificationService.approveRejectVerification(
        { reason: rejectionReason },
        id,
        "reject",
        "face_capture",
        who
      )
        .then((res) => {
          // console.log(res.data);
          toast.success(res?.data?.message);
          // handleRejectClose();
        })
        .catch((err) => {
          toast.error(err.response.data.error.message);
        })
        .finally(() => {
          stopRejectingKyc();
          handleRejectClose();
          // navigate('/kyc');
        });
  });

  const {
    loading: approvingKyc,
    startLoading: startApprovingKyc,
    stopLoading: stopApprovingKyc,
  } = useLoading(false);

  const onSubmit = () => {
    // console.log(data);
    if (
      approvingKyc ||
      !verifyData?.verification?.face_capture?.face_capture_url
    ) {
      return;
    }
    startApprovingKyc();
    VerificationService.approveRejectVerification(
      { reason: "" },
      id,
      "approve",
      "face_capture",
      who
    )
      .then((res) => {
        // console.log(res.data);
        setShowModal(false);
        // toast.success(res?.data?.message || '');
        setTimeout(() => handleOpen(), 1000);
      })
      .catch((err) => {
        toast.error(err.response.data.error.message);
      })
      .finally(() => {
        stopApprovingKyc();
        // navigate('/artisans');
      });
  };
  const INPUTVAL = !!verifyData?.verification?.face_capture?.face_capture_url;

  return (
    <>
      <form className="w-[60%] pb-[29px] pt-[74px]">
        <div className="flex flex-col justify-between items-center h-full">
          <div className="p-[34px] w-full max-w-[530px] mx-auto">
            <h3 className="font-medium text-2xl">Face Recognition</h3>
            <div className="max-w-sm w-full lg:flex mt-2">
              <div className="border border-gray-400 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
                <div className="mb-8">
                  <div className="text-gray-900 font-bold text-xl mb-2 flex items-center">
                    <svg className="fill-current text-gray-500 w-3 h-3 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z" />
                    </svg>
                    Notification for Compliance Team:</div>
                  <p className="text-gray-700 text-base">
                    Please ensure to validate the user's source of verification fee payment from Flutterwave dashboard 
                    before approving their face verification application. This step is crucial to maintain compliance with 
                    policies and regulations.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-10 bg-[#7507BD] w-[307px] h-[316px] mx-auto p-[15px] rounded-full">
              {verifyData?.verification?.face_capture?.face_capture_url ? (
                <img
                  src={verifyData?.verification?.face_capture?.face_capture_url}
                  alt="profile pics"
                  className="rounded-full bg-white w-full h-full"
                ></img>
              ) : (
                ""
              )}
            </div>
          </div>
          {verifyData?.artisan?.verified?.face_capture ||
          verifyData?.user?.verified?.face_capture ? (
            <div className="flex gap-2 items-center justify-center">
              <img src="/svgs/verified.svg" alt="" className="w-9 h-9" />
              <p className="text-[#667085] font-semibold">
                Validation Confirmed
              </p>
            </div>
          ) : INPUTVAL ? (
            <div className="flex gap-6 justify-center">
              <Button
                onClick={(e?: any) => {
                  e.preventDefault();
                  setShowModal(true);
                }}
                disabled={approvingKyc}
                classes={`bg-[#7607BD] w-[100px] py-2 flex justify-center items-center gap-2 text-white ${
                  verifyData?.verification?.face_capture?.face_capture_url
                    ? ""
                    : "cursor-not-allowed"
                } ${isOccupManager ? '!hidden' : ''}`}
              >
                Accept
              </Button>
              <Button
                onClick={(e?: any) => {
                  e.preventDefault();
                  if (
                    !verifyData?.verification?.face_capture?.face_capture_url
                  ) {
                    return;
                  } else {
                    handleRejectOpen();
                  }
                }}
                classes={`border border-[#D92D20] w-[100px] py-2 flex justify-center items-center gap-2 text-[#D92D20] ${
                  verifyData?.verification?.face_capture?.face_capture_url
                    ? ""
                    : "cursor-not-allowed"
                } ${isOccupManager ? '!hidden' : ''}`}
              >
                Reject
              </Button>
            </div>
          ) : (
            ""
          )}
          {/* {verifyData?.verification?.face_capture?.face_capture_url} */}
        </div>
        <KycApprovedModal
          open={open}
          handleClose={handleClose}
          name={`${
            verifyData?.artisan?.first_name || verifyData?.user?.first_name
          } ${verifyData?.artisan?.last_name || verifyData?.user?.last_name}`}
        />
        <RejectionModal
          open={openReject}
          handleRejectKyc={handleRejectKyc}
          handleClose={handleRejectClose}
          rejectionReason={rejectionReason}
          setRejectionReason={setRejectionReason}
          rejectingKyc={rejectingKyc}
        />
      </form>

      <AppModal
        open={showModal}
        onClose={() => setShowModal(false)}
        content={
          <ModalContent
            content1="Accept Face Recognition"
            content2="Are you sure you want to accept face?"
            btnAction={handleSubmit(onSubmit)}
            linkContent={"Yes, Please"}
            loading={approvingKyc}
            onClick={() => setShowModal(false)}
          />
        }
      />
    </>
  );
};

export default FaceRecognition;
