import adminServiceApi from "src/utils/AdminServiceApi";

const userServices = {
	getUsers: async () => {
		return adminServiceApi().get("user/v1/");
	},
	getStaleUsers: async () => {
		return adminServiceApi().get("user/v1/dashboard/user/stale");
	},
	deleteStaleUsers: async (payload: { userIds: string[] }) => {
		return adminServiceApi().delete(`user/v1/dashboard/user/stale`, {
			data: payload,
		});
	},
	getUsersVerification: async () => {
		return adminServiceApi().get("user/v1/verification");
	},
	getUserVerification: async (user_id: string) => {
		return adminServiceApi().get(`user/v1/verification/${user_id}`);
	},
	getUser: async (user_id: string) => {
		return adminServiceApi().get(`user/v1/profile/${user_id}`);
	},
	updateUser: async (user_id: string, data: any) => {
		return adminServiceApi().put(`user/v1/profile/${user_id}`, data);
	},
	validateAccount: async (payload: {
		account_number: string;
		bank_code: string;
	}) => {
		return adminServiceApi().post(
			"user/v1/admin/wallet/verify-account",
			payload
		);
	},
	updateWithdrawalBank: async (payload: {
		account_number: string;
		bank_code: string;
		bank_name: string;
		user_id: string;
	}) => {
		return adminServiceApi().post("user/v1/admin/wallet/bank", payload);
	},
	DumpCustomerData: async () => {
		return adminServiceApi().get("user/v1/dashboard/dump-data");
	},
	suspendUser: async (user_id: string, data: any) => {
		return adminServiceApi().post(`user/v1/${user_id}/suspend`, data);
	},
	unSuspendUser: async (user_id: string, data: any) => {
		return adminServiceApi().post(`user/v1/${user_id}/unsuspend`, data);
	},
	chargeVerificationFee: async (user_id: string) => {
		return adminServiceApi().post(`user/v1/verification/${user_id}/charge-fee`);
	},
	deleteProfilePic: async (user_id: string) => {
		return adminServiceApi().put(
			`user/v1/profile/remove_profile_picture/${user_id}`
		);
	},
};

export default userServices;
