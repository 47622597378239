import { ExportToCsv } from "export-to-csv";

export const useExportData = (
  data: any | undefined,
  headers: string[] | undefined
) => {
  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: headers,
  };

  const csvExporter = new ExportToCsv(csvOptions);

  function getExportedData() {
    csvExporter.generateCsv(data);
  }

  return getExportedData;
};
