import { useNavigate } from "react-router-dom";
import { usePagination } from "src/hooks/usePagination";
import { FC, useState } from "react";
import { ActionMenu, Flex, Table, Button, ButtonClass } from "src/components/ui";
import { theme } from "src/styles/Theme";
import SendMailModal from "../users/SendMailModal";
import SendNotificationModal from "../users/SendNotificationModal";
import { useBoolean } from "src/hooks/useBoolean";
import { ArtisanTableContainer, PopupContainer } from "./artisan-style";
import { useLoading } from "src/hooks";
import { ArtisansServices } from "src/service/ArtisansServices";
import { toast, ToastContainer } from "react-toastify";

const ArtisanStaleTable: FC<{ filteredRow: any }> = ({ filteredRow }) => {
  const [openSendNotificationModal, setOpenSendNotificationModal] =
    useState(false);
  const [userId, setUserId] = useState<string[]>([]);
  const handleOpenNotificationModal = (id: string) => {
    setOpenSendNotificationModal(true);
    setUserId((prevState: string[]) => [...prevState, id]);
  };
  const handleCloseNotificationModal = () =>
    setOpenSendNotificationModal(false);

  const [openSendMailModal, setOpenSendMailModal] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const handleOpenMailModal = (email: string, name: string) => {
    setUserEmail(email);
    setUserName(name);
    setOpenSendMailModal(true);
  };
  const handleCloseMailModal = () => setOpenSendMailModal(false);
  const [selectedFilterValue, setSelectedFilterValue] = useState("all");
  const { loading, startLoading, stopLoading } = useLoading();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFilterValue(event.target.value);
  };

  let navigate = useNavigate();
  const [allowRowClick, setAllowRowClick] = useState(true);

  const handleNavigate = (row: any) => {
    navigate(`/vendors/${row?._id}?tabStatus=all`);
  };

   const [selectedRows, setSelectedRows] = useState<string[]>([]);
   const [selectAllChecked, setSelectAllChecked] = useState(false);

  const selectRow = (row: any) => {
    const rowId = row._id;
    if (selectedRows.includes(rowId)) {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((id) => id !== rowId)
      );
    } else {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, rowId]);
    }
  };

  const selectAllRows = () => {
  setSelectAllChecked(!selectAllChecked);
  setSelectedRows(selectAllChecked ? [] : filteredRow.map((row: any) => row._id));
};

    const handleDelete = () =>{
        startLoading();
        console.log(selectedRows, 'selected stale accounts');
        ArtisansServices.deleteStaleArtisans({
          artisanIds : selectedRows
        })
        .then((res) => {
        // console.log(res?.data?.payload?.data);
        toast(res?.data?.payload?.data);
      })
      .catch((err: any) => {
        // console.log(err?.response?.data?.error?.message);
        toast.error(err?.response?.data?.error?.message);
      })
      .finally(() => stopLoading());
    }

  const ArtisanStaleTableHeaders = [
    {
        title: '',
         render: (row: any) =>(
            <input
                type="checkbox"
                checked={selectedRows.includes(row._id)}
                onChange={() => selectRow(row)}
            />
         ),
    },
    {
      title: "PhotoIcon",
      render: (row: any) => (
        <img
          style={{
            width: "40px",
            height: 40,
            borderRadius: "50%",
            objectFit: "contain",
          }}
          src={row.display_picture}
          alt=""
        />
      ),
    },
    {
      title: "First Name",
      render: (row: any) => `${row.first_name}`,
    },
    {
      title: "Last Name",
      render: (row: any) => `${row.last_name}`,
    },
    {
      title: "Email",
      render: (row: any) => `${row.email}`,
    },
    {
      title: "Account Deleted",
      render: (row: any) => `${row.deleted ? 'Yes' : 'No'}`,
    },
    {
      title: "Status",
      render: (row: any) => (
        <div
          style={{
            color: row.status === "Online" ? "#55C4F1" : "#FFAD4A",
          }}
        >
          {row.status}
        </div>
      ),
    },
    {
      title: "Suspended",
      render: (row: any) => (
        <div
          style={{
            color: row.suspended ? "#EB5656" : "#55c4f1",
          }}
        >
          {row.suspended ? "Yes" : "No"}
        </div>
      ),
    },
    {
      title: "Gender",
      render: (row: any) => `${row.gender}`,
    },
    {
      title: "Occupation",
      render: (row: any) => `${row.occupation}`,
    },
    {
      title: "Phone",
      render: (row: any) => `${row.phone}`,
    },
    {
      title: '--',
      render: (row: any) => (
        <ActionMenu
          setAllowRowClick={(bool: boolean) => {
            setAllowRowClick(bool);
          }}
          actions={[
            {
              title: "View profile",
              onClick: () => handleNavigate(row),
            },
            {
              title: "Send email",
              onClick: () => handleOpenMailModal(row.email, row.first_name),
            },
            {
              title: "Send notification",
              onClick: () => handleOpenNotificationModal(row._id),
            },
          ]}
        />
      ),
    },

  ];

  const sortedData = () => {
    if (selectedFilterValue === "all") {
      return filteredRow;
    } else if (selectedFilterValue === "offline") {
      return filteredRow.filter((item: any) =>
        item.status.toLowerCase().includes("offline")
      );
    } else if (selectedFilterValue === "online") {
      return filteredRow.filter((item: any) =>
        item.status.toLowerCase().includes("online")
      );
    } else {
      return filteredRow;
    }
  };

  const { page, limit, Pagination } = usePagination({
    page: 1,
    limit: 10,
    total: sortedData().length,
  });
  const paginatedRows = sortedData().slice((page - 1) * limit, page * limit);
  const { value: isOpen, setFalse: closeAction, toggle } = useBoolean(false);

  return (
    <ArtisanTableContainer>
        <ToastContainer />
      <div className="heading">
        <Flex justify="space-between" align="center">
          <p className="count">
            {sortedData().length > 1
              ? sortedData().length + " Vendors"
              : sortedData().length + " Vendor"}
          </p>
          <Flex justify="space-between" align="center">
            <input type="checkbox" 
                checked={selectAllChecked}
                onChange={selectAllRows}
            />
            <span>Select All</span>
            <Button
                onClick={handleDelete}
                classes={[ButtonClass.SOLID, ButtonClass.WITH_ICON]}
                style={{
                backgroundColor: theme.colors.red,
                height: "48px",
                minWidth: "145px",
                }}
                loading={loading}
                disabled={selectedRows.length === 0}
            >
                <span>Delete</span>
            </Button>
          </Flex>
        </Flex>
      </div>

      <SendMailModal
        open={openSendMailModal}
        userEmail={userEmail}
        user={userName}
        handleClose={handleCloseMailModal}
      />
      <SendNotificationModal
        open={openSendNotificationModal}
        userId={[]}
        artisan={true}
        handleClose={handleCloseNotificationModal}
      />
      <Table
        rows={paginatedRows}
        headers={ArtisanStaleTableHeaders}
        showHead
        allowRowClick={allowRowClick}
        onRowClick={selectRow}
      />

      <Pagination />
    </ArtisanTableContainer>
  );
};

export default ArtisanStaleTable;
