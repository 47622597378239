import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
// import { Link } from "react-router-dom";
import { useLoading } from "src/hooks";
import VerificationService from "src/service/VerifyService";
import { TextField } from "../../components/ui/form/FormComponent";
import Button from "../../components/ui/widget/Button";
// import ConfirmApproveKycModal from "../../components/kyc/kycModals/ConfirmApproveKycModal";
import RejectionModal from "../../components/kyc/kycModals/RejectionModal";
import KycApprovedModal from "src/components/kyc/kycModals/KycApprovedModal";
import { toast } from "react-toastify";
import AppModal from "src/components/ui/widget/Modal/Modal";
import ModalContent from "src/components/common/ModalContent";

type Props = {
  verifyData: { [key: string]: any };
  id: string;
  who: string;
  fetchData: Function;
};
type NinDataType = {
  firstname: string;
  surname: string;
  telephoneno: string;
  birthdate: string;
};

const NinValidation = ({ verifyData, id, who, fetchData }: Props) => {
  const { formState, handleSubmit, register, control, setValue } = useForm({
    mode: "onChange",
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    fetchData(id);
  };
  const [openReject, setOpenReject] = useState(false);
  const handleRejectOpen = () => setOpenReject(true);
  const handleRejectClose = () => {
    setOpenReject(false);
    fetchData(id);
  };
  const [rejectionReason, setRejectionReason] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [ninData, setNinData] = useState<NinDataType | undefined | null>({
    firstname: "",
    surname: "",
    telephoneno: "",
    birthdate: "",
  });

  const {
    loading: approvingKyc,
    startLoading: startApprovingKyc,
    stopLoading: stopApprovingKyc,
  } = useLoading(false);

  const {
    loading: rejectingKyc,
    startLoading: startRejectingKyc,
    stopLoading: stopRejectingKyc,
  } = useLoading(false);
  const {
    loading: retryingKyc,
    startLoading: startRetryingKyc,
    stopLoading: stopRetryingKyc,
  } = useLoading(false);

  const INPUTVAL = !!(
    verifyData?.verification?.nin?.nin_no ||
    verifyData?.verification?.nin?.vnin_no
  );

  useEffect(() => {
    setValue("nin", verifyData?.verification?.nin?.nin_no || "");
    setValue("vnin", verifyData?.verification?.nin?.vnin_no || "");
  }, [
    setValue,
    verifyData?.verification?.nin?.nin_no,
    verifyData?.verification?.nin?.vnin_no,
  ]);

  //effect for setting ninData
  useEffect(() => {
    if (verifyData && typeof window !== "undefined") {
      const data: NinDataType = verifyData?.verification?.nin?.nin_data;

      if (data) {
        if (Object.keys(data).length !== 0) {
          setNinData({
            firstname: data?.firstname,
            surname: data?.surname,
            telephoneno: data?.telephoneno,
            birthdate: data.birthdate,
          });
        } else {
          setNinData(null);
        }
      } else {
        setNinData(undefined);
      }
    }
  }, [verifyData, ninData]);

  const handleRejectKyc = handleSubmit((e?: any) => {
    if (rejectingKyc) {
      return;
    }
    startRejectingKyc();
    id &&
      VerificationService.approveRejectVerification(
        { reason: rejectionReason },
        id,
        "reject",
        "nin",
        who
      )
        .then((res) => {
          // console.log(res.data);
          toast.success(res.data.message);
        })
        .catch((err) => {
          console.error(err.response.data.error.message);
          toast.error(err.response.data.error.message);
        })
        .finally(() => {
          stopRejectingKyc();
          // navigate('/kyc');
        });
  });

  const handleApprove = () => {
    // console.log(data);
    if (approvingKyc || !INPUTVAL) {
      return;
    }
    startApprovingKyc();
    VerificationService.approveRejectVerification(
      { reason: "" },
      id,
      "approve",
      "nin",
      who
    )
      .then((res) => {
        // console.log(res.data);
        setShowModal(false);
        setTimeout(() => handleOpen(), 1000);
      })
      .catch((err) => {
        // console.error(err.response.data.error.message);
        toast.error(err.response.data.error.message);
      })
      .finally(() => {
        stopApprovingKyc();
      });
  };

  const handleRetry = () => {
    if (retryingKyc) {
      return;
    }
    startRetryingKyc();
    VerificationService.retryNinVerification(
      {
        nin_no: verifyData?.verification?.nin?.nin_no,
        vnin_no: verifyData?.verification?.nin?.vnin_no,
        id,
      },
      who
    )
      .then((res) => {
        // console.log(res.data);
        setTimeout(() => handleOpen(), 1000);
      })
      .catch((err) => {
        console.error(err.response.data.error.message);
        toast.error(err.response.data.error.message);
      })
      .finally(() => {
        stopRetryingKyc();
      });
  };

  return (
    <>
      <form className="w-[60%] pb-[29px] pt-[74px]">
        <div className="flex flex-col justify-between h-full">
          <div className="p-[34px] w-full max-w-[530px] mx-auto">
            <h3 className="font-medium text-2xl">Nin Validation</h3>
            <div className="mt-10">
              <TextField
                label={"NIN"}
                type="text"
                disabled={true}
                control={control}
                error={formState.errors.nin}
                {...register("nin", { required: false })}
              />
              <TextField
                label={"V-NIN"}
                type="text"
                disabled={true}
                control={control}
                error={formState.errors.vnin}
                {...register("vnin", { required: false })}
              />

              {ninData !== undefined ? (
                <div className="w-full flex items-start justify-start flex-col gap-[10px] border border-solid border-[#7607BD] rounded-lg min-h-[226px] p-[12px]">
                  {ninData ? (
                    <>
                      <h2 className="text-[20px] text-[#4D4D4D]">
                        Information on{" "}
                        <span className="font-[20px] text-[#7607BD]">
                          NIN: {verifyData?.verification?.nin?.nin_no}
                        </span>
                      </h2>

                      <div className="flex items-center justify-start gap-2 text-[16px]">
                        First name:
                        <span className="font-semibold text-[16px] capitalize">
                          {ninData
                            ? ninData?.firstname?.toLocaleLowerCase()
                            : "N/A"}
                        </span>
                      </div>

                      <div className="flex items-center justify-start gap-2 text-[16px]">
                        Last name:
                        <span className="font-semibold text-[16px] capitalize">
                          {ninData
                            ? ninData?.surname?.toLocaleLowerCase()
                            : "N/A"}
                        </span>
                      </div>
                      <div className="flex items-center justify-start gap-2 text-[16px]">
                        Phone Number:
                        <span className="font-semibold text-[16px] capitalize">
                          {ninData ? ninData?.telephoneno : "N/A"}
                        </span>
                      </div>
                      <div className="flex items-center justify-start gap-2 text-[16px]">
                        Date of Birth:
                        <span className="font-semibold text-[16px] capitalize">
                          {ninData ? ninData?.birthdate : "N/A"}
                        </span>
                      </div>
                    </>
                  ) : (
                    <h2 className="text-[20px] font-semibold text-[#4D4D4D]">
                      No NIN data available.
                    </h2>
                  )}
                </div>
              ) : null}
            </div>
          </div>

          {verifyData?.artisan?.verified?.nin ||
          verifyData?.user?.verified?.nin ? (
            <div className="flex gap-2 items-center justify-center">
              <img src="/svgs/verified.svg" alt="" className="w-9 h-9" />
              <p className="text-[#667085] font-semibold">
                Validation Confirmed
              </p>
            </div>
          ) : ninData === null ? (
            <Button
              onClick={(e?: any) => {
                e.preventDefault();
                handleRetry();
              }}
              disabled={retryingKyc}
              classes={`bg-[#7607BD] py-2 flex justify-center items-center gap-2 text-white w-[100px] mx-auto ${
                retryingKyc ? "cursor-not-allowed" : ""
              }`}
            >
              Retry
            </Button>
          ) : INPUTVAL ? (
            <div className="flex gap-6 justify-center">
              <Button
                onClick={(e?: any) => {
                  e.preventDefault();
                  setShowModal(true);
                }}
                disabled={approvingKyc}
                classes={`bg-[#7607BD] py-2 flex justify-center items-center gap-2 text-white w-[100px] ${
                  INPUTVAL ? "" : "cursor-not-allowed"
                }`}
              >
                Accept
              </Button>
              <Button
                onClick={(e?: any) => {
                  e.preventDefault();
                  if (!INPUTVAL) {
                    return;
                  } else {
                    handleRejectOpen();
                  }
                }}
                classes={`border border-[#D92D20] py-2 flex justify-center items-center gap-2 text-[#D92D20] w-[100px] ${
                  INPUTVAL ? "" : "cursor-not-allowed"
                }`}
              >
                Reject
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
        <KycApprovedModal
          open={open}
          handleClose={handleClose}
          name={`${
            verifyData?.artisan?.first_name || verifyData?.user?.first_name
          } ${verifyData?.artisan?.last_name || verifyData?.user?.last_name}`}
        />
        <RejectionModal
          open={openReject}
          handleRejectKyc={handleRejectKyc}
          handleClose={handleRejectClose}
          rejectionReason={rejectionReason}
          setRejectionReason={setRejectionReason}
          rejectingKyc={rejectingKyc}
        />
      </form>

      <AppModal
        open={showModal}
        onClose={() => setShowModal(false)}
        content={
          <ModalContent
            content1="Accept NIN Document"
            content2="Are you sure you want to accept NIN Document?"
            btnAction={handleSubmit(handleApprove)}
            linkContent={"Yes, Please"}
            loading={approvingKyc}
            onClick={() => setShowModal(false)}
          />
        }
      />
    </>
  );
};

export default NinValidation;
