import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import styled from "styled-components";
import { Flex, Button, ButtonClass } from "src/components/ui";
import closeIcon from "src/assets/images/common/closeIcon.svg";
import { theme } from "src/styles/Theme";
import miscService from "src/service/miscServices";
import states from "src/service/states";
import { toast } from "react-toastify";

const Wrapper = styled.div`
  .heading {
    background-color: ${(props) => props.theme.colors.gray_04};
    padding: 10px 20px;
    height: 56px;
    align-items: center;
    display: flex;
    border-radius: 8px 8px 0 0;

    img {
      padding: 8px;
      &:hover {
        cursor: pointer;
        background-color: ${(props) => props.theme.colors.lilac};
        transition: 0.3s;
        border-radius: 50%;
      }
    }

    p {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: ${(props) => props.theme.colors.text_01};
    }
  }

  .content {
    border-radius: 0 0 8px 8px;
    padding: 20px;
    .label {
      font-size: 14px;
      line-height: 20px;
      color: ${(props) => props.theme.colors.text_01};
      margin-bottom: 5px;
    }
    input,
    textarea {
      padding: 10px 14px;
      border: 1px solid ${(props) => props.theme.colors.text_02};
      background-color: ${(props) => props.theme.colors.gray_04};
      color: ${(props) => props.theme.colors.text_01};
      font-size: 16px;
      line-height: 24px;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      width: 100%;
      height: 44px;
      outline: none;
      &:focus {
        border: 1px solid ${(props) => props.theme.colors.purple};
      }
    }

    textarea {
      resize: none;
      height: 235px;
      background-color: ${(props) => props.theme.colors.white};
    }
    .button {
      padding: 8px 15px;
      background-color: ${(props) => props.theme.colors.gray_04};
      border-radius: 8px;
    }
    .error {
      font-weight: 200;
      color: red;
      margin-top: 3px;
    }
  }
`;
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 532,
  maxWidth: "90%",
  bgcolor: theme.colors.white,
  borderRadius: "8px",
  boxShadow: 24,
  outline: "none",
};

interface IFilterData {
    tier: number | string;
    state: string;
    gender: string;
    occupation: string;
}

const initialFilterState: IFilterData = {
    tier: "",
    state: "",
    gender: "",
    occupation: ""
}

export const FilterNotificationModal: React.FC<{
    open: boolean;
    handleClose: () => void;
    user: string;
    setFilterState: React.Dispatch<React.SetStateAction<IFilterData>>;
}> = ({ open, handleClose, user, setFilterState }) => {
    const [filterPayload, setFilterPayload] = React.useState<IFilterData>(initialFilterState);

    const [occupations, setOccupations] = React.useState<{name: string;}[]>([]);
    React.useEffect(() => {
		miscService
			.getOccupations()
			.then((res) => {
				// console.log(res?.data?.payload);
				setOccupations(res?.data?.payload.data);
			})
			.catch((err: any) => {
				// console.log(err.response.data.error.message);
        toast.error(err.response.data.error.message);
			});
	}, []);

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const {
        target: { value, name },
      } = event;
      setFilterPayload((prev) => ({ ...prev, [name]: value }));
    };

    const saveHandler = (e: any) => {
        e.preventDefault();
        // console.log(filterPayload);
        setFilterState(filterPayload);
        resetHandler();
        handleClose();
    }

    const resetHandler = () => {
        // console.log('reset');
        setFilterPayload(initialFilterState);
    }

    return (
        <>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
        <Fade in={open}>
          <Box sx={style}>
            <Wrapper>
              <div className="heading">
                <Flex
                  justify="space-between"
                  align="center"
                  style={{ width: "100%" }}
                >
                  <p>Filter</p>
                  <img src={closeIcon} alt="x" onClick={handleClose} />
                </Flex>
              </div>
              <div className="content">
                <Flex direction="column">
                  
                  <div className="to">
                    <p className="label">Tier</p>
                    <select
                      id='tier'
                      name='tier'
                      placeholder='Select Tier'
                      onChange={handleSelectChange}
                      style={{ background: "#F2F4F7" }}
                      className={`border border-[#98a2b3] text-sm rounded-lg placeholder:text-[#98a2b3] block w-full px-[10px] py-[14px]`}
                      value={filterPayload.tier}
                    >
                        <option value=''>Select a tier</option>
                        { user === 'artisan' ? 
                            [0,1,2,3,4,5].map((tier) => (
                                <option value={tier}>{tier}</option>
                            )) : 
                            [0,1,2,3,4].map((tier) => (
                                <option value={tier}>{tier}</option>
                            ))
                        }
                    </select>
                  </div>
                  <div className="to">
                    <p className="label">Gender</p>
                    <select
                      id='gender'
                      name='gender'
                      placeholder='Select Gender'
                      onChange={handleSelectChange}
                      style={{ background: "#F2F4F7" }}
                      className={`border border-[#98a2b3] text-sm rounded-lg placeholder:text-[#98a2b3] block w-full px-[10px] py-[14px]`}
                      value={filterPayload.gender}
                    >
                        <option value=''>Select a gender</option>
                        <option value='male'>Male</option>
                        <option value='female'>Female</option>
                    </select>
                  </div>
                  { user === 'artisan' &&
                    <div className="to">
                        <p className="label">Occupation</p>
                        <select
                            id='occupation'
                            name='occupation'
                            placeholder='Select Occupation'
                            onChange={handleSelectChange}
                            style={{ background: "#F2F4F7" }}
                            className={`border border-[#98a2b3] text-sm rounded-lg placeholder:text-[#98a2b3] block w-full px-[10px] py-[14px]`}
                            value={filterPayload.occupation}
                            >
                                <option value=''>Select an occupation</option>
                            {occupations.map((occupation: any) => (
                                <option key={occupation._id} value={occupation.name}>
                                {occupation.name}
                                </option>
                            ))}
                            </select>
                    </div>
                  }
                  
                <div className="to">
                    <p className="label">Location</p>
                    <select
                        id='location'
                        name='state'
                        onChange={handleSelectChange}
                        style={{ background: "#F2F4F7" }}
                        className={`border border-[#98a2b3] text-sm rounded-lg placeholder:text-[#98a2b3] block w-full px-[10px] py-[14px]`}
                        value={filterPayload.state}
                        >
                            <option value=''>Select a state</option>
                        {states.map((state: any, index: number) => (
                            <option key={index} value={state}>
                            {state}
                            </option>
                        ))}
                    </select>
                </div>
                  <Flex justify="space-between">
                    <div className="button">
                        <Button
                            classes={[ButtonClass.NO_BG]}
                            onClick={resetHandler}
                            style={{
                            minWidth: "100px",
                            display: "flex",
                            justifyContent: "center",
                            color: theme.colors.red,
                            }}
                        >
                            <span>Reset all</span>
                        </Button>
                        </div>
                        <div className="button">
                        <Button
                            classes={[ButtonClass.SOLID]}
                            onClick={saveHandler}
                            style={{
                            backgroundColor: theme.colors.purple,
                            minWidth: "100px",
                            display: "flex",
                            justifyContent: "center",
                            }}
                        >
                            <span>Save</span>
                        </Button>
                        </div>
                    </Flex>
                </Flex>
              </div>
            </Wrapper>
          </Box>
        </Fade>
        </Modal>
        </>
    );
};

export default FilterNotificationModal;