import adminServiceApi from "src/utils/AdminServiceApi";

export const ArtisansServices = {
	getAllArtisans: async () => {
		return adminServiceApi().get("artisan/v1/");
	},
	getAllStaleArtisans: async () => {
		return adminServiceApi().get("artisan/v1/dashboard/artisan/stale");
	},
	deleteStaleArtisans: async (payload: { artisanIds: string[] }) => {
		return adminServiceApi().delete(`artisan/v1/dashboard/artisan/stale`, {
			data: payload,
		});
	},
	getAllArtisansVerification: async () => {
		return adminServiceApi().get("artisan/v1/verification");
	},
	getArtisan: async (artisan_id: string) => {
		return adminServiceApi().get(`artisan/v1/profile/${artisan_id}`);
	},
	getArtisanVerification: async (artisan_id: string) => {
		return adminServiceApi().get(`artisan/v1/verification/${artisan_id}`);
	},
	suspendArtisan: async (artisan_id: string, data: any) => {
		return adminServiceApi().post(`artisan/v1/${artisan_id}/suspend`, data);
	},
	unSuspendArtisan: async (artisan_id: string, data: any) => {
		return adminServiceApi().post(`artisan/v1/${artisan_id}/unsuspend`, data);
	},
	updateArtisan: async (artisan_id: string, data: any) => {
		return adminServiceApi().put(`artisan/v1/profile/${artisan_id}`, data);
	},
	validateAccount: async (payload: {
		account_number: string;
		bank_code: string;
	}) => {
		return adminServiceApi().post(
			"artisan/v1/admin/wallet/verify-account",
			payload
		);
	},
	updateWithdrawalBank: async (payload: {
		account_number: string;
		bank_code: string;
		bank_name: string;
		user_id?: string;
		artisan_id?: string;
	}) => {
		return adminServiceApi().post("artisan/v1/admin/wallet/bank", payload);
	},
	DumpArtisansData: async () => {
		return adminServiceApi().get("artisan/v1/dashboard/dump-data");
	},
	chargeVerificationFee: async (artisan_id: string) => {
		return adminServiceApi().post(
			`artisan/v1/verification/${artisan_id}/charge-fee`
		);
	},
	deleteProfilePic: async (artisan_id: string) => {
		return adminServiceApi().put(
			`artisan/v1/profile/remove_profile_picture/${artisan_id}`
		);
	},
	bringVendorsOnline: async (status: string) => {
		return adminServiceApi().put(`artisan/v1/profile/all/availability`, {
			status,
		});
	},
};
