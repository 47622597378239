import React, { useEffect, useState } from "react";
import { DashboardLayout } from "src/components/dashboard";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Loader } from "src/components/common";
import { toast, ToastContainer } from "react-toastify";
import {  ActionMenu } from "src/components/ui";
import { useLoading } from "src/hooks";
import { formatDateDmy } from "src/utils";
import CustomerTabs from "src/components/Requests/CustomerTab";
import { requestServices } from "src/service/requestServices";
import { Input } from "src/components/inputs";
import searchIcon from "src/assets/images/input/searchIcon.svg";


interface Props {
  handleChange: (e: any) => void;
}

interface TransactionDetails {
  debited_for: string;
  account_number: string;
  status: string;
  bank_name: string;
  user_id: number;
}

interface RequestTypes {
  transaction_details: TransactionDetails;
  debited_for: string;
  amount: string;
  created_at: string;
  prev_balance: string;
  new_balance: string;
}

export const RhsHeading: React.FC<Props> = ({ handleChange }) => (
  <Input
    icon={<img src={searchIcon} alt="searchIcon" />}
    type="search"
    placeholder="Search"
    handleChange={handleChange}
  />
);

const CustomerWithdrawalRequest = () => {
	const [requestData, setRequestData] = useState<RequestTypes[]>([
		{} as RequestTypes,
	]);
	const navigate = useNavigate();
	let [searchParams, setSearchParams] = useSearchParams();
	const [searchField, setSearchField] = useState("");
	const [allowRowClick, setAllowRowClick] = useState(true);
	const handleChange = (e: any) => {
		setSearchField(e.target.value);
	};
  
	useEffect(() => {
		document.title = "Request Page";
	}, []);

	useEffect(() => {
		startLoading();
		requestServices.getAllUsersRequests()
		.then((res) => {
			setRequestData(res.data.payload?.data?.pending_transaction)
			
		})
		.catch((err) => {
			console.log(err.response);
		})
		.finally(() => stopLoading());
	}, [])

	const filteredData = requestData.filter((data) => {
		const account_name = data.debited_for ? data.debited_for.toLowerCase() : "";
		return account_name.includes(searchField.toLowerCase());
	});

	const handleNavigate = (row: { [x: string]: any }) => {
    navigate(`/customers/${row?.transaction_details?.user_id}`);
  };

  	const handleValidation = (row:any, value:string) =>{
		requestServices.validateUserRequest(`${row?.transaction_id}`, value)
		.then((res) => {
			toast.success(res?.data?.message);
			console.log(res);
			
		})
		.catch((err) => {
			toast.error(err)
			toast.error(err?.response?.data?.error?.message);
		})
	}
	
	const { loading, startLoading, stopLoading } = useLoading();
	  
	const RequestTableHeaders = [
		{
			title: "Account Name",
			render: (row: RequestTypes) => `${row.transaction_details.debited_for}`,
    	},
		{
			title: "Account No",
			render: (row: RequestTypes) => `${row.transaction_details.account_number}`,
    	},
		{
			title: "Amount",
			render: (row: RequestTypes) => `${row.amount}`,
    	},
		{
			title: "Date",
			render: (row: RequestTypes) => formatDateDmy(row.created_at),
    	},
		{
			title: "Status",
			render: (row: RequestTypes) => (
				<p
					className={`capitalize ${
						row.transaction_details.status === "pending" ? "text-[#55C4F1]" : "text-[#7607BD]"
					}`}
				>
					{row.transaction_details.status === "pending" ? "active" : row.transaction_details.status}
				</p>
			),
    	},
		{
			title: "Previous Balance",
			render: (row: RequestTypes) => `${row.prev_balance}`,
    	},
		{
			title: "New Balance",
			render: (row: RequestTypes) => `${row.new_balance}`,
    	},
		{
			title: "--",
			render: (row: UsersListTypes) => (
				<ActionMenu
				setAllowRowClick={(bool: boolean) => {
					setAllowRowClick(bool);
				}}
				actions={[
					{
					title: <p className=" text-blueX">Approve</p>,
					onClick: () => {
						handleValidation(row, 'approve');
					},
					},
					{
					title: <p className=" text-redX">Reject</p>,
					onClick: () => {
						handleValidation(row, 'reject');
					},
					},
					
				]}
				/>
			),
		},
	]
	console.log(filteredData);
	
	return (
		<div>
			<DashboardLayout
				pageTitle="Customer request withdrawal "
				rhsHeading={<RhsHeading handleChange={handleChange} />}
			>
				{/* <ToastContainer /> */}
				<div>
					{ loading ? (
						<div
							style={{
							display: "flex",
							justifyContent: "center",
							marginTop: "100px",
							}}
						>
							<Loader>loading...</Loader>{" "}
						</div>
					) : filteredData.length > 0 ?
						(
						<CustomerTabs
							rows= {requestData}
							BookingsTableHeaders={RequestTableHeaders}
							allowRowClick={allowRowClick}
							title={
								<p className="count">
									{filteredData.length > 1 ? filteredData.length : 0} Dispute
								</p>
								}
							onRowClick={handleNavigate}
							searchParams={searchParams}
							setSearchParams={setSearchParams}
						/>
						) : (
							<p className="table-entry-status">No Data Found</p>
							)
					}
				</div>
			</DashboardLayout>
		</div>
	)
}

export default CustomerWithdrawalRequest