import { useState } from "react";
import styled from "styled-components";
import { Flex, Table, ActionMenu, Button, ButtonClass } from "src/components/ui";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SendNotificationModal, SendMailModal } from "src/components/users";
import { usePagination } from "src/hooks/usePagination";
import { theme } from "src/styles/Theme";
import userServices from "src/service/userServices";
import { useLoading } from "src/hooks";
import { toast, ToastContainer } from "react-toastify";


const UsersTableContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  margin: 2rem 0;
  border-radius: 16px;
  padding-bottom: 20px;
  .heading {
    border-bottom: 1px solid ${(props) => props.theme.colors.gray_03};
    padding: 20px;

    .count {
      font-weight: 700;
      font-size: 17px;
      line-height: 27px;
      color: ${(props) => props.theme.colors.purple};
      background-color: ${(props) => props.theme.colors.gray_04};
      padding: 4px 12px;
      width: max-content;
      border-radius: 16px;
    }
  }
`;

interface Props {
  rows: any;
}

export const UsersStaleTable = ({ rows }: Props) => {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState<string[]>([]);

  const handleNavigate = (row: any) => {
    navigate(`/customers/${row?._id}?tabStatus=all`);
  };

  const [allowRowClick, setAllowRowClick] = useState(true);

  const [openSendNotificationModal, setOpenSendNotificationModal] =
    useState(false);
  const handleOpenSendNotificationModal = (id: string) => {
    setOpenSendNotificationModal(true);
    setUserId((prevState: string[]) => [...prevState, id]);
  };
  const handleCloseSendNotificationModal = () =>
    setOpenSendNotificationModal(false);

  const [openSendMailModal, setOpenSendMailModal] = useState(false);
  const handleOpenSendMailModal = (email: string, name: string) => {
    setUserEmail(email);
    setUserName(name);
    setOpenSendMailModal(true);
  };
  const handleCloseSendMailModal = () => setOpenSendMailModal(false);
  const { loading, startLoading, stopLoading } = useLoading();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
   const [selectAllChecked, setSelectAllChecked] = useState(false);

   const selectRow = (row: any) => {
    const rowId = row._id;
    if (selectedRows.includes(rowId)) {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((id) => id !== rowId)
      );
    } else {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, rowId]);
    }
  };

  const selectAllRows = () => {
  setSelectAllChecked(!selectAllChecked);
  setSelectedRows(selectAllChecked ? [] : rows.map((row: any) => row._id));
};

  const handleDelete = () =>{
        startLoading();
        console.log(selectedRows, 'selected stale accounts');
        userServices.deleteStaleUsers({
          userIds : selectedRows
        })
        .then((res) => {
        // console.log(res?.data?.payload?.data);
        toast(res?.data?.message);
      })
      .catch((err: any) => {
        // console.log(err?.response?.data?.error?.message);
        toast.error(err?.response?.data?.error?.message);
      })
      .finally(() => stopLoading());
    }

  const UsersTableHeaders = [
      {
        title: '',
         render: (row: any) =>(
            <input
                type="checkbox"
                checked={selectedRows.includes(row._id)}
                onChange={() => selectRow(row)}
            />
         ),
    },
    {
      title: "Name",
      render: (row: UsersListTypes) => (
        <Flex gap="10px" align="center">
          <img
            style={{ width: 40, height: 40, borderRadius: "50%" }}
            src={row.display_picture}
            alt=""
          />{" "}
          {row.first_name} {row.last_name}
        </Flex>
      ),
    },
    { title: "Email", render: (row: UsersListTypes) => `${row.email}` },
    { title: "Gender", render: (row: UsersListTypes) => `${row.gender}` },
    { title: "Tier", render: (row: UsersListTypes) => `${row.tier}` },
    { title: "Phone", render: (row: UsersListTypes) => `${row.phone}` },
    { title: "Account Deleted", render: (row: UsersListTypes) => `${row.deleted ? 'Yes' : 'No'}` },

    {
      title: "",
      render: (row: UsersListTypes) => (
        <ActionMenu
          setAllowRowClick={(bool: boolean) => {
            setAllowRowClick(bool);
          }}
          actions={[
            {
              title: "View profile",
              onClick: () => {
                handleNavigate(row);
              },
            },
            {
              title: "Send email",
              onClick: () => {
                handleOpenSendMailModal(row.email, row.first_name);
              },
            },
            {
              title: "Send notification",
              onClick: () => {
                handleOpenSendNotificationModal(row._id);
              },
            },
          ]}
        />
      ),
    },
  ];

  const { page, limit, Pagination } = usePagination({
    page: 1,
    limit: 10,
    total: rows.length,
  });
  const paginatedRows = rows.slice((page - 1) * limit, page * limit);

  return (
    <UsersTableContainer>
      <ToastContainer />
      <div className="heading">
        <Flex justify="space-between" align="center">
        
        <p className="count">
          {rows.length > 1
            ? rows.length + " Customers"
            : rows.length + " Customer"}
        </p>
        <Flex justify="space-between" align="center">
            <input type="checkbox" 
                    checked={selectAllChecked}
                    onChange={selectAllRows}
                />
                <span>Select All</span>
                <Button
                    onClick={handleDelete}
                    classes={[ButtonClass.SOLID, ButtonClass.WITH_ICON]}
                    style={{
                    backgroundColor: theme.colors.red,
                    height: "48px",
                    minWidth: "145px",
                    }}
                    loading={loading}
                    disabled={selectedRows.length === 0}
                >
                    <span>Delete</span>
                </Button>
        </Flex>
        </Flex>
      </div>
      <SendMailModal
        open={openSendMailModal}
        userEmail={userEmail}
        user={userName}
        handleClose={handleCloseSendMailModal}
      />
      <SendNotificationModal
        open={openSendNotificationModal}
        userId={[]}
        artisan={false}
        handleClose={handleCloseSendNotificationModal}
      />
      <Table
        rows={paginatedRows}
        headers={UsersTableHeaders}
        showHead={true}
        allowRowClick={allowRowClick}
        onRowClick={selectRow}
      />
      <Pagination />
    </UsersTableContainer>
  );
};

export default UsersStaleTable;
