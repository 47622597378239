import React, { useState, useEffect } from "react";
import {
	GoogleMap,
	Marker,
	InfoWindow,
	useJsApiLoader,
} from "@react-google-maps/api";

const containerStyle = {
	width: "100%",
	height: "400px",
};

interface MyComponentProps {
	coordinates: number[];
}

const MyComponent = ({ coordinates }: MyComponentProps) => {
	const lat = coordinates[1] ?? 0;
	const lng = coordinates[0] ?? 1;

	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY!,
	});

	const [locationName, setLocationName] = useState<string>("");
	const [showInfoWindow, setShowInfoWindow] = useState(false);

	const fetchLocationName = async (lat: number, lng: number) => {
		const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_GEOCODE_API_KEY}`;

		try {
			const response = await fetch(geocodingUrl);
			const data = await response.json();
			if (data.status === "OK") {
				const address = data.results[0]?.formatted_address;
				setLocationName(address);
			} else {
				console.error("Geocoding failed:", data.status);
				setLocationName("Unknown location");
			}
		} catch (error) {
			console.error("Error fetching location name:", error);
			setLocationName("Error fetching location");
		}
	};

	useEffect(() => {
		if (lat && lng) {
			fetchLocationName(lat, lng);
		}
	}, [lat, lng]);

	const handleMarkerClick = () => {
		setShowInfoWindow(true);
	};

	const handleInfoWindowClose = () => {
		setShowInfoWindow(false);
	};

	return isLoaded ? (
		<GoogleMap
			mapContainerStyle={containerStyle}
			center={{ lat, lng }}
			zoom={10}
			mapTypeId={google.maps.MapTypeId.ROADMAP} // Ensure the map type is set
		>
			<Marker position={{ lat, lng }} onClick={handleMarkerClick} />

			{showInfoWindow && (
				<InfoWindow
					position={{ lat, lng }}
					onCloseClick={handleInfoWindowClose}
				>
					<div>
						<h4>{locationName || "Loading..."}</h4>
					</div>
				</InfoWindow>
			)}
		</GoogleMap>
	) : (
		<div>Loading map...</div>
	);
};

export default React.memo(MyComponent);
