import { Input } from "src/styles/commonStyle";
import styled from "styled-components";
import { formatDateYmd } from "src/utils/helpers";
import { Button, ButtonClass, Flex } from "src/components/ui";
import { ChangeEvent, useEffect, useState } from "react";
import KycData from "src/service/VerifyService";
import { ClipLoader } from "react-spinners";
import useLoading from "src/hooks/useLoading";
import { auth } from "src/store/Auth";
import AdminAuth from "src/service/AdminAuth";
import { Controller, useForm } from "react-hook-form";
import { NumberInput } from "../inputs/NumberInput";
import { toast } from "react-toastify";
import VerificationService from "src/service/VerifyService";
import { useParams } from "react-router-dom";
import { ArtisansServices } from "src/service/ArtisansServices";
import { theme } from "src/styles/Theme";
import { SelectField } from "../ui/form/FormComponent";
import miscService from "src/service/miscServices";
import { SelectChangeEvent } from "@mui/material/Select";

const ProfileContainer = styled.div`
	padding: 40px;
	background: #ffffff;
	width: 100%;
	margin-top: 36px;
	border-radius: 16px;
	h3 {
		font-size: 28px;
		font-weight: 600;
		margin-bottom: 32px;
	}
	.input_wrapper {
		display: grid;
		grid: auto / auto auto;
		gap: 20px;
	}
`;

interface Props {
	verifyData: any;
}

interface ServiceFeeType {
	service_fee_min: string;
	service_fee_max: string;
}

interface VendorDataType {
	first_name: string;
	last_name: string;
	occupation: string;
	dob: string;
	gender: "male" | "female";
	email: string;
	phone: string;
	status: "online" | "offline" | "busy";
	city: string;
	state: string;
	address: string;
	can_edit_profile: "yes" | "no ";
	can_withdraw: "yes" | "no ";
	paid_verification_fee?: boolean;
}

interface OccupationTypes {
	name: string;
}

const VendorProfileInfo = ({ verifyData }: Props) => {
	const {
		register,
		handleSubmit,
		control,
		setValue,
		// reset,
		formState: { errors },
	} = useForm<ServiceFeeType>({
		mode: "onChange",
	});
	const [minServiceFee, setMinServiceFee] = useState("");
	const [maxServiceFee, setMaxServiceFee] = useState("");
	const [serviceFee, setServiceFee] = useState({ min: "", max: "" });
	const [vendorData, setVendorData] = useState<VendorDataType>({
		first_name: verifyData?.artisan?.first_name,
		last_name: verifyData?.artisan?.last_name,
		occupation: verifyData?.artisan?.occupation,
		dob: verifyData?.artisan?.dob,
		gender: verifyData?.artisan?.gender,
		email: verifyData?.artisan?.email,
		phone: verifyData?.artisan?.phone,
		status: verifyData?.artisan?.status,
		city: verifyData?.artisan?.address?.city,
		state: verifyData?.artisan?.address?.state,
		address: verifyData?.artisan?.address?.house_address,
		can_edit_profile: verifyData?.artisan?.can_edit_profile,
		can_withdraw: verifyData?.artisan?.can_withdraw,
		paid_verification_fee: verifyData?.artisan?.meta?.paid_verification_fee,
	});

	const { artisan_id } = useParams();
	//Check if Logged Admin is an Occupational Manager
	const { authUser } = auth.use();

	const [isOccupManager, setIsOccupManager] = useState(false);

	useEffect(() => {
		//Set State to true is Occupational Manager is logged in
		if (authUser?.role?.includes("occupationalManager")) {
			setIsOccupManager(true);
		}
	}, [authUser]);

	const { loading, startLoading, stopLoading } = useLoading(false);

	useEffect(() => {
		setMinServiceFee(verifyData?.artisan?.service_fee?.min || "");
		setValue("service_fee_min", verifyData?.artisan?.service_fee?.min || "");
		setValue("service_fee_max", verifyData?.artisan?.service_fee?.max || "");
		setMaxServiceFee(verifyData?.artisan?.service_fee?.max || "");
	}, [setValue, verifyData?.artisan?.service_fee]);

	useEffect(() => {
		AdminAuth.getServiceFeeRange()
			.then((res) => {
				// console.log(res.data);
				setServiceFee(res.data.payload.data.service_fee_range);
			})
			.catch((err) => console.log(err.response));
	}, []);

	function handleChange(e: ChangeEvent<HTMLInputElement>) {
		const { name, value } = e.target;
		setVendorData((prev) => ({ ...prev, [name]: value }));
	}

	const [occupations, setOccupations] = useState<OccupationTypes[]>([]);
	useEffect(() => {
		miscService
			.getOccupations()
			.then((res) => {
				// console.log(res?.data?.payload);
				setOccupations(res?.data?.payload.data);
			})
			.catch((err: any) => {
				// console.log(err.response.data.error.message);
				toast.error(err.response.data.error.message);
			});
	}, []);

	const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
		const {
			target: { value, name },
		} = event;
		setVendorData((prev) => ({ ...prev, [name]: value }));
	};

	const {
		loading: updatingVendor,
		startLoading: startUpdatingVendor,
		stopLoading: stopUpdatingVendor,
	} = useLoading();

	function handleUpdateVendor(e: any) {
		e.preventDefault();

		const data: VendorDataType = {
			first_name: vendorData?.first_name,
			last_name: vendorData?.last_name,
			occupation: vendorData?.occupation,
			dob: vendorData?.dob,
			gender: vendorData?.gender,
			email: vendorData?.email,
			phone: vendorData?.phone,
			status: vendorData?.status,
			city: vendorData?.city,
			state: vendorData?.state,
			address: vendorData?.address,
			can_edit_profile: vendorData?.can_edit_profile,
			can_withdraw: vendorData?.can_withdraw,
		};

		startUpdatingVendor();

		artisan_id &&
			ArtisansServices.updateArtisan(artisan_id, data)
				.then((res) => {
					// console.log(res.data);
					toast.success(res?.data?.message || []);
				})
				.catch((err) => {
					toast.error(err.response.data.error.message);
				})
				.finally(() => {
					stopUpdatingVendor();
				});
	}

	const onSubmit = (data: ServiceFeeType) => {
		if (data) {
			// console.log(data);
			startLoading();
			VerificationService.updateCalloutFee(verifyData?.artisan._id, data)
				.then((res) => toast.success(res?.data?.message))
				.catch((err) => toast.error(err?.response?.data?.error?.message))
				.finally(() => stopLoading());
		}
	};

	const {
		loading: chargeVerificationFeeLoading,
		startLoading: startChargeVerificationFeeLoading,
		stopLoading: stopChargeVerificationFeeLoading,
	} = useLoading();

	function chargeVerificationFee(e: any) {
		e.preventDefault();

		startChargeVerificationFeeLoading();

		artisan_id &&
			ArtisansServices.chargeVerificationFee(artisan_id)
				.then((res) => {
					// console.log(res.data);
					toast.success(res?.data?.message || "");
				})
				.catch((err) => {
					toast.error(err.response.data.error.message);
				})
				.finally(() => {
					stopChargeVerificationFeeLoading();
				});
	}

	return (
		<ProfileContainer>
			<div className='flow-root'>
				<div className='float-left'>
					<h3 className='text-neutral text-3xl'>Profile Information</h3>
				</div>
				<div className='float-right'>
					{!vendorData?.paid_verification_fee && (
						<Button
							disabled={chargeVerificationFeeLoading}
							loading={chargeVerificationFeeLoading}
							classes={[ButtonClass.SOLID]}
							style={{ backgroundColor: theme.colors.purple }}
							onClick={chargeVerificationFee}
						>
							Charge Verification Fee
						</Button>
					)}
				</div>
			</div>

			<div
				style={{
					backgroundImage: `url(${
						verifyData?.artisan?.display_picture || "/images/profilePics.png"
					})`,
					width: "153px",
					height: "153px",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					objectFit: "cover",
					backgroundSize: "cover",
					boxShadow:
						"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
					borderRadius: "50%",
				}}
			></div>
			<br />
			<br />

			<Flex direction='column' gap='2.5rem'>
				<Flex>
					<Flex direction='column' style={{ width: "50%" }}>
						<label htmlFor='first_name'>First Name</label>
						<Input
							name='first_name'
							style={{ background: "#F2F4F7" }}
							value={vendorData?.first_name}
							onChange={handleChange}
						/>
					</Flex>

					<Flex direction='column' style={{ width: "50%" }}>
						<label htmlFor='last_name'>Last Name</label>
						<Input
							name='last_name'
							style={{ background: "#F2F4F7" }}
							value={vendorData?.last_name}
							onChange={handleChange}
						/>
					</Flex>
				</Flex>

				<Flex>
					<Flex direction='column' style={{ width: "50%" }}>
						<label htmlFor='occupation'>Occupation</label>
						<select
							id='occupation'
							name='occupation'
							placeholder='Select Occupation'
							onChange={handleSelectChange}
							style={{ background: "#F2F4F7" }}
							className={`border border-[#98a2b3] text-sm rounded-lg placeholder:text-[#98a2b3] block w-full px-[10px] py-[14px]`}
							value={vendorData?.occupation}
						>
							{occupations.map((occupation: any) => (
								<option key={occupation._id} value={occupation.name}>
									{occupation.name}
								</option>
							))}
						</select>
					</Flex>

					<Flex direction='column' style={{ width: "50%" }}>
						<label htmlFor='dob'>Date of birth</label>
						<Input
							name='dob'
							type='date'
							style={{ background: "#F2F4F7" }}
							value={formatDateYmd(vendorData?.dob)}
							onChange={handleChange}
						/>
					</Flex>
				</Flex>

				<Flex>
					<Flex direction='column' style={{ width: "50%" }}>
						<label htmlFor='address'>Address</label>
						<Input
							name='address'
							type='text'
							style={{ background: "#F2F4F7" }}
							value={vendorData.address}
							onChange={handleChange}
						/>
					</Flex>
					<Flex direction='column' style={{ width: "50%" }}>
						<label htmlFor='city'>City</label>
						<Input
							name='city'
							type='text'
							style={{ background: "#F2F4F7" }}
							value={vendorData.city}
							onChange={handleChange}
						/>
					</Flex>
				</Flex>
				<Flex>
					<Flex direction='column' style={{ width: "50%" }}>
						<label htmlFor='state'>State</label>
						<Input
							name='state'
							type='text'
							style={{ background: "#F2F4F7" }}
							value={vendorData.state}
							onChange={handleChange}
						/>
					</Flex>
					<Flex direction='column' style={{ width: "50%" }}>
						<label htmlFor='gender'>Gender</label>
						<select
							id='gender'
							name='gender'
							placeholder='Select gender'
							onChange={handleSelectChange}
							style={{ background: "#F2F4F7" }}
							className={`border border-[#98a2b3] text-sm rounded-lg placeholder:text-[#98a2b3] block w-full px-[10px] py-[14px]`}
							value={vendorData?.gender}
						>
							<option value='male'>Male</option>
							<option value='female'>Female</option>
						</select>
					</Flex>
				</Flex>

				<Flex>
					<Flex direction='column' style={{ width: "50%" }}>
						<label htmlFor='first_name'>Email</label>
						<Input
							name='email'
							style={{ background: "#F2F4F7" }}
							value={vendorData?.email}
							onChange={handleChange}
						/>
					</Flex>
					<Flex direction='column' style={{ width: "50%" }}>
						<label htmlFor='first_name'>Phone Number</label>
						<Input
							name='phone'
							style={{ background: "#F2F4F7" }}
							value={vendorData?.phone}
							onChange={handleChange}
						/>
					</Flex>
				</Flex>

				<Flex>
					<Flex direction='column' style={{ width: "50%" }}>
						<label htmlFor='first_name'>Status</label>
						<select
							id='status'
							name='status'
							placeholder='Select status'
							onChange={handleSelectChange}
							style={{ background: "#F2F4F7" }}
							className={`border border-[#98a2b3] text-sm rounded-lg placeholder:text-[#98a2b3] block w-full px-[10px] py-[14px]`}
							value={vendorData?.status}
						>
							<option value='online'>online</option>
							<option value='offline'>offline</option>
							<option value='busy'>busy</option>
						</select>
					</Flex>
					<Flex direction='column' style={{ width: "50%" }}>
						<label htmlFor='can_edit_profile'>Can Edit Profile</label>
						<select
							id='can_edit_profile'
							name='can_edit_profile'
							placeholder='Can Edit Profile'
							onChange={handleSelectChange}
							style={{ background: "#F2F4F7" }}
							className={`border border-[#98a2b3] text-sm rounded-lg placeholder:text-[#98a2b3] block w-full px-[10px] py-[14px]`}
							value={vendorData?.can_edit_profile ? "Yes" : "No"}
						>
							<option value='Yes'>Yes</option>
							<option value='No'>No</option>
						</select>
					</Flex>
				</Flex>
				<Flex direction='column' style={{ width: "50%" }}>
					<label htmlFor='can_withdraw'>Can Withdraw</label>
					<select
						id='can_withdraw'
						name='can_withdraw'
						placeholder='Can Withdraw'
						onChange={handleSelectChange}
						style={{ background: "#F2F4F7" }}
						className={`border border-[#98a2b3] text-sm rounded-lg placeholder:text-[#98a2b3] block w-full px-[10px] py-[14px]`}
						value={vendorData?.can_withdraw ? "Yes" : "No"}
					>
						<option value='Yes'>Yes</option>
						<option value='No'>No</option>
					</select>
				</Flex>

				<div className='mb-8 flex'>
					<Button
						disabled={updatingVendor}
						loading={updatingVendor}
						classes={[ButtonClass.SOLID]}
						style={{ backgroundColor: theme.colors.purple }}
						onClick={handleUpdateVendor}
						className={`${isOccupManager ? "!hidden" : ""}`}
					>
						Update Vendor Data
					</Button>
				</div>

				<form onSubmit={handleSubmit(onSubmit)}>
					<Flex align='end'>
						<Flex gap='5px' direction='column' style={{ width: "154px" }}>
							<NumberInput
								label={`Service Fee Minimum (${serviceFee?.min})`}
								control={control}
								{...register("service_fee_min", {
									required: true,
									min: serviceFee?.min,
								})}
								error={errors.service_fee_min}
							/>
						</Flex>
						<div className='h-12 flex items-center'>
							<div className='text-black h-[1px] w-2 bg-black' />
						</div>
						<Flex gap='5px' direction='column' style={{ width: "174px" }}>
							<NumberInput
								label={`Service Fee Maximum (${serviceFee?.max})`}
								control={control}
								{...register("service_fee_max", {
									required: true,
									max: serviceFee?.max,
								})}
								error={errors.service_fee_max}
							/>
						</Flex>
						<button
							type='submit'
							// onClick={updateCalloutFee}
							className='text-primary border border-primary active:bg-primary active:text-white font-semibold rounded-lg text-sm px-2 py-3 min-w-[112px] h-full'
						>
							{loading ? (
								<ClipLoader
									size={20}
									color={"#7607BD"}
									className='text-primary'
								/>
							) : (
								"Update"
							)}
						</button>
					</Flex>
				</form>
			</Flex>
		</ProfileContainer>
	);
};

export default VendorProfileInfo;
