import adminServiceApi from "src/utils/AdminServiceApi";

const VerificationService = {
  getUsersVerification: async () => {
    return adminServiceApi().get("user/v1/verification");
  },
  getUserVerification: async (user_id: string) => {
    return adminServiceApi().get(`user/v1/verification/${user_id}`);
  },
  getAllVendorsVerification: async () => {
    return adminServiceApi().get("artisan/v1/verification");
  },
  getVendorVerification: async (artisan_id: string) => {
    return adminServiceApi().get(`artisan/v1/verification/${artisan_id}`);
  },
  approveRejectVerification: async (
    data: { reason: string },
    artisan_id: string,
    action: string,
    type: string,
    who: string
  ) => {
    return adminServiceApi().put(
      `${who}/v1/verification/${artisan_id}/${action}?type=${type}`,
      data
    );
  },
  retryNinVerification: async (
    data: {
      nin_no: string;
      vnin_no: string;
      id: string;
    },
    who: string
  ) => {
    // console.log(data, who);
    return adminServiceApi().post(`${who}/v1/verification/nin/retry`, data);
  },
  updateCalloutFee: async (
    artisan_id: string,
    data: { service_fee_min: string; service_fee_max: string }
  ) => {
    return adminServiceApi().put(
      `artisan/v1/verification/${artisan_id}/service-fee`,
      data
    );
  },
};

export default VerificationService;
