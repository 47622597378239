import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import prevPageIcon from "./icons/prevPageIcon.svg";
import nextPageIcon from "./icons/nextPageIcon.svg";
import { Flex, Button, ButtonClass } from "src/components/ui";
import { useLocation } from "react-router-dom";

const PaginationContainer = styled.div`
	border-top: 1px solid ${(props) => props.theme.colors.gray_03};
	padding: 10px 20px 0 20px;

	/* .limit-changer {
    display: flex;
    align-items: center;
    gap: 1rem;

    & > :last-child {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  } */

	/* select {
    appearance: none;
    outline: none;
    border: 2px solid ${(props) => props.theme.colors.inputBorder};
    border-radius: 4px;
    color: ${(props) => props.theme.colors.text_02};
    background-color: ${(props) => props.theme.colors.white};
    background-image: url(caretDownIcon);
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    background-size: 10px;
    border-radius: 4px;
    padding: 4px 10px;
    line-height: 20px;
    letter-spacing: 0.25px;
    padding: 4px calc(10px + 1rem) 4px 10px;
    cursor: pointer;
    transition: 0.5s;

    &:focus {
      border: 2px solid ${(props) => props.theme.colors.primaryColor};
    }
  } */

	.pagination {
		display: flex;
		gap: 5px;
		align-items: center;

		ul {
			li {
				button {
					display: flex;
					align-items: center;
					justify-content: center;
					min-width: 37px;
					height: 37px;
					padding: 2px 4px;
					background-color: ${(props) => props.theme.colors.transparent};
					border-radius: 2px;
					border: none;
					outline: none;
					cursor: pointer;
					font-weight: 500;
					font-size: 16px;
					line-height: 20px;
					color: ${(props) => props.theme.colors.text_01};

					&[disabled] {
						opacity: 0.4;
					}

					&:hover {
						color: ${(props) => props.theme.colors.purple};
					}
				}
			}
		}

		.page-numbers {
			list-style-type: none;
			display: flex;
			gap: 5px;
			padding: 0;
			margin: 0;
			flex-wrap: wrap;

			.page-numbers__link--active {
				background-color: ${(props) => props.theme.colors.lilac};
				color: ${(props) => props.theme.colors.purple};
			}
		}
	}
`;

interface Props {
	page?: any;
	setPage?: any;
	limit?: any;
	setLimit?: any;
	total?: any;
	pageNumbers?: any;
	disablePrevPage?: any;
	disableNextPage?: any;
	gotoPrevPage?: any;
	gotoNextPage?: any;
	gotoFirstPage: () => void;
	gotoLastPage: () => void;
}

export const Pagination: React.FC<Props> = ({
	page,
	setPage,
	limit,
	setLimit,
	total,
	pageNumbers,
	disablePrevPage,
	disableNextPage,
	gotoPrevPage,
	gotoNextPage,
	gotoFirstPage,
	gotoLastPage,
	...rest
}) => {
	const [inputValue, setInputValue] = useState(page.toString());
	const location = useLocation();
	const pageKey = `${location.pathname}_page`;

	const handleInputChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setInputValue(e.target.value);
		},
		[]
	);

	const handleInputBlur = useCallback(() => {
		const newPage = parseInt(inputValue, 10);
		if (!isNaN(newPage) && newPage >= 1 && newPage <= total) {
			localStorage.setItem("page", newPage.toString());
			localStorage.setItem("pageKey", pageKey);
			setPage(newPage);
		} else {
			setInputValue(page.toString());
		}
	}, [inputValue, total, setPage, page]);

	return (
		<PaginationContainer {...rest}>
			{pageNumbers.length > 0 && (
				<div className='pagination'>
					<Flex
						justify='space-between'
						align='center'
						style={{ width: "100%" }}
					>
						<p className='text-[16px]'>
							Page {page} of {pageNumbers.length}
						</p>

						<div className='flex gap-2'>
							<Button
								style={{
									border: "1px solid #D0D5DD",
									boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
									borderRadius: "8px",
									width: "auto",
								}}
								classes={[ButtonClass.OUTLINED, ButtonClass.WITH_ICON]}
								disabled={page === 1}
								onClick={gotoFirstPage}
							>
								<img src={prevPageIcon} alt='<' />
								<span>First</span>
							</Button>
							<Button
								style={{
									border: "1px solid #D0D5DD",
									boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
									borderRadius: "8px",
									width: "auto",
								}}
								classes={[ButtonClass.OUTLINED, ButtonClass.WITH_ICON]}
								disabled={disablePrevPage}
								onClick={gotoPrevPage}
							>
								{/* <img src={prevPageIcon} alt="<" /> */}
								<span>Previous</span>
							</Button>
							<Button
								classes={[ButtonClass.OUTLINED, ButtonClass.WITH_ICON]}
								style={{
									border: "1px solid #D0D5DD",
									boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
									borderRadius: "8px",
									width: "auto",
								}}
								disabled={disableNextPage}
								onClick={gotoNextPage}
							>
								{/* <img src={nextPageIcon} alt=">" /> */}
								<span>Next</span>
							</Button>
							<input
								type='number'
								className='w-10 border-gray-200 border-[1px] rounded-md flex items-center justify-center text-center'
								value={inputValue}
								onChange={handleInputChange}
								onBlur={handleInputBlur}
								onKeyUp={(e) => {
									if (e.key === "Enter") {
										handleInputBlur();
									}
								}}
							/>
							<Button
								classes={[ButtonClass.OUTLINED, ButtonClass.WITH_ICON]}
								style={{
									border: "1px solid #D0D5DD",
									boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
									borderRadius: "8px",
									width: "auto",
								}}
								disabled={page === pageNumbers.length}
								onClick={gotoLastPage}
							>
								<img src={nextPageIcon} alt='>' />
								<span>Last</span>
							</Button>
						</div>
					</Flex>
				</div>
			)}
		</PaginationContainer>
	);
};
