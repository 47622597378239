import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import styled from "styled-components";
import { Flex, Button, ButtonClass, Loading } from "src/components/ui";
import closeIcon from "src/assets/images/common/closeIcon.svg";
import { theme } from "src/styles/Theme";
import { AdminServices } from "src/service/AdminServices";
import { toast, ToastContainer } from "react-toastify";
import miscService from "src/service/miscServices";
import userServices from "src/service/userServices";
import { ArtisansServices } from "src/service/ArtisansServices";

const Wrapper = styled.div`
  .heading {
    background-color: ${(props) => props.theme.colors.gray_04};
    padding: 10px 20px;
    height: 56px;
    align-items: center;
    display: flex;
    border-radius: 8px 8px 0 0;

    img {
      padding: 8px;
      &:hover {
        cursor: pointer;
        background-color: ${(props) => props.theme.colors.lilac};
        transition: 0.3s;
        border-radius: 50%;
      }
    }

    p {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: ${(props) => props.theme.colors.text_01};
    }
  }

  .content {
    border-radius: 0 0 8px 8px;
    padding: 20px;
    .label {
      font-size: 14px;
      line-height: 20px;
      color: ${(props) => props.theme.colors.text_01};
      margin-bottom: 5px;
    }
    input,
    textarea {
      padding: 10px 14px;
      border: 1px solid ${(props) => props.theme.colors.text_02};
      background-color: ${(props) => props.theme.colors.gray_04};
      color: ${(props) => props.theme.colors.text_01};
      font-size: 16px;
      line-height: 24px;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      width: 100%;
      height: 44px;
      outline: none;
      &:focus {
        border: 1px solid ${(props) => props.theme.colors.purple};
      }
    }

    textarea {
      resize: none;
      height: 235px;
      background-color: ${(props) => props.theme.colors.white};
    }
    .button {
      padding: 8px 15px;
      background-color: ${(props) => props.theme.colors.gray_04};
      border-radius: 8px;
    }
    .error {
      font-weight: 200;
      color: red;
      margin-top: 3px;
    }
  }
`;
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 532,
  maxWidth: "90%",
  bgcolor: theme.colors.white,
  borderRadius: "8px",
  boxShadow: 24,
  outline: "none",
};

export const ChangeWithdrawalBankAccount: React.FC<{
  open: boolean;
  userId: string;
  artisan: boolean;
  withdrawalBank: {
    account_name: string;
    account_number: string;
    bank_name: string;
    bank_code: string;
  };
  handleClose: () => void;
}> = ({ open, handleClose, userId, artisan, withdrawalBank }) => {
  const [isDisabled, setIsDisabled] = React.useState<boolean>(true);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isValidateButtonDisabled, setIsValidateButtonDisabled] = React.useState<boolean>(false);
  const [banks, setBanks] = React.useState<any[]>([]);
  const [withdrawalBankPayload, setWithdrawalBankPayload] = React.useState({
    account_name: withdrawalBank?.account_name || "",
    account_number: withdrawalBank?.account_number || "",
    bank_name: withdrawalBank?.bank_name || "",
    bank_code: withdrawalBank?.bank_code || "",
  });

  React.useEffect(() => {
    miscService
        .getBanks()
        .then((res) => {
            // console.log(res?.data?.payload?.data);
            setBanks(res?.data?.payload.data);
        })
        .catch((err) => console.log(err.response))
}, []);

    const onChange = async (e: any) => {
        if(e.target.name === 'bank_code') {
            setWithdrawalBankPayload({
                ...withdrawalBankPayload,
                bank_name: e.target.selectedOptions[0].text,
                [e.target.name]: e.target.value,
            });
        } else {
            setWithdrawalBankPayload({
                ...withdrawalBankPayload,
                [e.target.name]: e.target.value,
            });
        }
    }

  const submitHandler = (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        if(artisan) {
            ArtisansServices.updateWithdrawalBank({
                ...withdrawalBankPayload,
                artisan_id: userId
            })
            .then((res) => {
            toast.success(res?.data.message);
            })
            .catch((err: any) => {
            toast.error(err.response.error.message);
            })
            .finally(() => {
                setIsDisabled(true);
                setIsLoading(false);
            });
        } else {
            userServices.updateWithdrawalBank({
                ...withdrawalBankPayload,
                user_id: userId
            })
            .then((res) => {
            toast.success(res?.data.message);
            })
            .catch((err: any) => {
            toast.error(err.response.error.message);
            })
            .finally(() => {
                setIsDisabled(true);
                setIsLoading(false);
            });
        }
    }

    const validateAccountHandler = (e: any) => {
        e.preventDefault();
        setIsValidateButtonDisabled(true);
        if(artisan) {
            ArtisansServices.validateAccount({ 
                account_number: withdrawalBankPayload.account_number,
                bank_code: withdrawalBankPayload.bank_code,
             })
            .then((res) => {
                console.log(res?.data);
                toast.success(res?.data.message);
                setWithdrawalBankPayload({
                    ...withdrawalBankPayload,
                    account_name: res?.data?.payload?.data?.account_name,
                });
            })
            .catch((err: any) => {
                // console.log(err.response.error.message);
                toast.error(err.response.error.message);
            })
            .finally(() => {
                setIsValidateButtonDisabled(false);
                setIsDisabled(false);
            });
        } else {
            userServices.validateAccount({ 
                account_number: withdrawalBankPayload.account_number,
                bank_code: withdrawalBankPayload.bank_code,
             })
            .then((res) => {
                // console.log(res?.data);
                toast.success(res?.data.message);
                setWithdrawalBankPayload({
                    ...withdrawalBankPayload,
                    account_name: res?.data?.payload?.data?.account_name,
                });
            })
            .catch((err: any) => {
                // console.log(err.response.error.message);
                toast.error(err.response.error.message);
            })
            .finally(() => {
                setIsValidateButtonDisabled(false);
                setIsDisabled(false);
            });
        }
    }

  return (
    <>
      <ToastContainer />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Wrapper>
              <div className="heading">
                <Flex
                  justify="space-between"
                  align="center"
                  style={{ width: "100%" }}
                >
                  <p>Change Withdrawal Bank Account</p>
                  <img src={closeIcon} alt="x" onClick={handleClose} />
                </Flex>
              </div>
              <div className="content">
                <Flex direction="column">
                  <div className="to">
                    <p className="label">Account Number</p>
                    <input
                      type="text"
                      value={withdrawalBankPayload.account_number}
                      onChange={onChange}
                      name="account_number"
                    />
                  </div>
                  <div className="to">
                    <select
                        id='bank_code'
                        name='bank_code'
                        placeholder='Bank'
                        onChange={onChange}
                        className={`border border-[#98a2b3] text-sm rounded-lg placeholder:text-[#98a2b3] block w-full px-[10px] py-[14px]`}
                        value={withdrawalBankPayload.bank_code}
                    >
                        {banks.map((bank: any) => (
                            <option key={bank.code} value={bank.code}>
                                {bank.name}
                            </option>
                        ))}
                    </select>
                    </div>
                  <div className="to">
                    <p className="label">Account Name</p>
                    <input
                      type="text"
                      value={withdrawalBankPayload.account_name}
                      disabled={true}
                    />
                  </div>
                  <Flex justify="space-between">
                    <div className="button">
                      <Button
                        classes={[ButtonClass.SOLID]}
                        onClick={validateAccountHandler}
                        disabled={isValidateButtonDisabled}
                      >
                        {isValidateButtonDisabled ? (
                          <Loading color="white" />
                        ) : (
                          <span>Validate Account</span>
                        )}
                      </Button>
                    </div>
                    <div className="button">
                      <Button
                        classes={[ButtonClass.SOLID]}
                        onClick={submitHandler}
                        disabled={isDisabled}
                        style={{
                          backgroundColor: theme.colors.purple,
                          minWidth: "100px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {isLoading ? (
                          <Loading color="white" />
                        ) : (
                          <span>Submit</span>
                        )}
                      </Button>
                    </div>
                  </Flex>
                </Flex>
              </div>
            </Wrapper>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ChangeWithdrawalBankAccount;
