import {useEffect} from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useState } from 'react';
import { InputContainer } from '../admin/addAdminModal/AddAdminModal';
import miscService from 'src/service/miscServices';

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};



function getStyles(name: string, occupationName: string[], theme: Theme) {
	return {
		fontWeight:
			occupationName.indexOf(name) === -1
				? theme.typography.fontWeightRegular
				: theme.typography.fontWeightMedium,
	};
}

export default function OccupationMultiSelect({
	setOccupation, 
	occupationError,
}: {
	setOccupation: Function;
	occupationError: string;
}) {
	const theme = useTheme();
	const [occupationName, setOccupationName] = useState<string[]>([]);
	// const [clicked, setClicked] = useState(false);
    const [occupations, setOccupations] = useState([]);

	const handleChange = (event: SelectChangeEvent<typeof occupationName>) => {
        const {
			target: { value },
		} = event;
        const newValue = typeof value === 'string' ? value.split(',') : value;
        const selectedOccupation = [newValue[newValue.length - 1]]
        
        // setOccupation((occup: string[] ) => ({
		// 	occupation: occup,
		// 	occupationError: '',
		// }));

        setOccupationName(selectedOccupation);
		setOccupation((prevState: any) => ({
			...prevState,
			occupation: selectedOccupation,
			occupationError: '',
		}))
        
    };
    useEffect(() => {
        miscService
        .getOccupations()
        .then((res) => {
            console.log(res?.data?.payload?.data);
            const occupationObj = res?.data?.payload.data
            setOccupations(occupationObj.map((item: any) => item.name))
            console.log(occupations); 
            
        })
        .catch((err) => console.log(err.response))
    }, [])
    

	// React.useEffect(() => {
	// 	console.log(occupationName);
	// }, [occupationName]);

	return (
		<>
			<InputContainer>
				<label htmlFor='Occupation'>Occupation</label>
				<FormControl fullWidth size='small'>
					<Select
						labelId='demo-multiple-name-label'
						id='demo-multiple-name'
						multiple
						value={occupationName} 
						onChange={handleChange}
						style={{ padding: '0px auto' }}
						MenuProps={MenuProps}
						required
					>
						{occupations.map((role) => (
							<MenuItem
								key={role}
								value={role} 
								style={getStyles(role, occupationName, theme)}
							>
								{role}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				{occupationError && <h6 className='validation_error'>{occupationError}</h6>}
			</InputContainer>
		</>
	);
}
